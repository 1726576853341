import { ref } from "vue"
import store from "@/app/oa/store"
import { defineStore } from "pinia"
import { type RouteRecordRaw } from "vue-router"
import router, { constantRoutes, dynamicRoutes } from "@/app/oa/router"
import { flatMultiLevelRoutes } from "@/app/oa/router/helper"
import routeSettings from "@/config/route"
import { MenuInfo } from "@/api/menu/types/MenuIInfo"
import { IRouteState } from "@/app/oa_mobile/store/modules/route"

const Layouts = () => import("@oa/layouts/index.vue")

//const modules = import.meta.glob("@oa/views/**/**.vue")
const modules = import.meta.glob("../../views/**/**.vue")

// const hasPermission = (roles: string[], route: RouteRecordRaw) => {
//   const routeRoles = route.meta?.roles
//   return routeRoles ? roles.some((role) => routeRoles.includes(role)) : true
// }

// const filterDynamicRoutes = (routes: RouteRecordRaw[], roles: string[]) => {
//   const res: RouteRecordRaw[] = []
//   routes.forEach((route) => {
//     const tempRoute = { ...route }
//     if (hasPermission(roles, tempRoute)) {
//       if (tempRoute.children) {
//         tempRoute.children = filterDynamicRoutes(tempRoute.children, roles)
//       }
//       res.push(tempRoute)
//     }
//   })
//   return res
// }
const hasPermission = (menus: Array<MenuInfo>, route: RouteRecordRaw) => {
  return route.name ? menus.some((me) => route.name.toString() == me.menuName) : true
  // const routeRoles = route.meta?.roles
  // return routeRoles ? menus.some((role) => routeRoles.includes(role)) : true
}

const filterDynamicChildrenRoutes = (pid, menus) => {
  const res = []
  if (menus && pid) {
    const currentMenus = menus.filter((f) => f.parentFunctionID == pid)
    if (currentMenus && currentMenus.length > 0) {
      currentMenus.forEach((menu) => {
        if (menu.navigationController) {
          const tempRoute = {
            name: menu.menuName,
            path: menu.navigationController,
            component: menu.menuComponent ? modules[`../..${menu.menuComponent}`] : null,
            //component:() => import("@oa/views/oa_project/projectList.vue"),//
            meta: {
              title: menu.outlookName,
              icon: menu.platformIconID, //"iconfont el-icon-work-management",
              alwaysShow: menu.alwaysShow,
              keepAlive: menu.keepAlive,
              hidden: menu.hidden,
              functionId: menu.functionId,
              isCollection: menu.isCollection
              // listType: menu.listType
            }
          }
          if (menu.alias) {
            // @ts-ignore
            tempRoute.alias = [menu.alias]
          }
          if (menu.menuOtherMeta) {
            const otherMetaJson = JSON.parse(menu.menuOtherMeta)
            tempRoute.meta = { ...tempRoute.meta, ...otherMetaJson }
          }
          if (menu.children && menu.children.length > 0) {
            // tempRoute.children=[]
            // @ts-ignore
            tempRoute.alwaysShow = true
            // @ts-ignore
            tempRoute.children = filterDynamicChildrenRoutes(menu.functionId, menu.children)
          }
          res.push(tempRoute)
        }
      })
    }
  }
  return res
}

const filterDynamicRoutes = (routes: RouteRecordRaw[], parentID, menus: Array<MenuInfo>) => {
  const res: RouteRecordRaw[] = []

  if (menus) {
    menus.forEach((menu) => {
      if (parentID && parentID == menu.parentFunctionID) {
        if (menu.navigationController) {
          const tempRoute = {
            name: menu.menuName,
            path: menu.navigationController,
            component: menu.menuComponent ? modules[`${menu.menuComponent}`] : Layouts,
            meta: {
              title: menu.outlookName,
              icon: menu.platformIconID, //"iconfont el-icon-work-management",
              alwaysShow: menu.alwaysShow,
              keepAlive: menu.keepAlive,
              hidden: menu.hidden,
              functionId: menu.functionId,
              isCollection: menu.isCollection
            } //,
            // children:null
          }
          // @ts-ignore
          if (menu.alias) {
            // @ts-ignore
            tempRoute.alias = [menu.alias]
          }
          // @ts-ignore
          if (menu.menuOtherMeta) {
            // @ts-ignore
            const otherMetaJson = JSON.parse(menu.menuOtherMeta)
            tempRoute.meta = { ...tempRoute.meta, ...otherMetaJson }
          }

          if (menu.children && menu.children.length > 0) {
            // @ts-ignore
            tempRoute.children = filterDynamicChildrenRoutes(menu.functionId, menu.children)
          }
          res.push(tempRoute)
        }
      }
    })
  }
  return res
}

export const usePermissionStoreOld = defineStore("permission", () => {
  /** 可访问的路由 */
  const routes = ref<RouteRecordRaw[]>([])
  /** 有访问权限的动态路由 */
  const addRoutes = ref<RouteRecordRaw[]>([])

  /** 根据角色生成可访问的 Routes（可访问的路由 = 常驻路由 + 有访问权限的动态路由） */
  // const setRoutes = (roles: string[]) => {
  //   const accessedRoutes = filterDynamicRoutes(dynamicRoutes, roles)
  //   _set(accessedRoutes)
  // }

  const setRoutes = (menus: Array<MenuInfo>) => {
    const accessedRoutes = filterDynamicRoutes(dynamicRoutes, "g", menus)

    _set(accessedRoutes)
  }

  /** 所有路由 = 所有常驻路由 + 所有动态路由 */
  const setAllRoutes = () => {
    _set(dynamicRoutes)
  }

  const _set = (accessedRoutes: RouteRecordRaw[]) => {
    routes.value = constantRoutes.concat(accessedRoutes)
    addRoutes.value = routeSettings.thirdLevelRouteCache ? flatMultiLevelRoutes(accessedRoutes) : accessedRoutes
  }

  return { routes, addRoutes, setRoutes, setAllRoutes }
})

export const usePermissionStore = defineStore("permission", {
  state: (): IRouteState => {
    return {
      /** 可访问的路由 */
      routes: [],
      /** 有访问权限的动态路由 */
      addRoutes: []
    }
    /** 根据角色生成可访问的 Routes（可访问的路由 = 常驻路由 + 有访问权限的动态路由） */
    // const setRoutes = (roles: string[]) => {
    //   const accessedRoutes = filterDynamicRoutes(dynamicRoutes, roles)
    //   _set(accessedRoutes)
    // }
  },
  actions: {
    setRoutes(menus: Array<MenuInfo>) {
      const accessedRoutes = filterDynamicRoutes(dynamicRoutes, "g", menus)

      this._set(accessedRoutes)
    },

    /** 所有路由 = 所有常驻路由 + 所有动态路由 */
    setAllRoutes() {
      this._set(dynamicRoutes)
    },

    _set(accessedRoutes: RouteRecordRaw[]) {
      this.routes = constantRoutes.concat(accessedRoutes)
      this.addRoutes = routeSettings.thirdLevelRouteCache ? flatMultiLevelRoutes(accessedRoutes) : accessedRoutes
    }
  }
})

/** 在 setup 外使用 */
export function usePermissionStoreHook() {
  return usePermissionStore(store)
}
